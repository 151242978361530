
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Select from '@/components/Select.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
import Tab from '@/components/Tab.vue'

@Options({components: {Input, Button, Icon, Select, Loading, Tab, Error}})
export default class LorHome extends Vue {

    playerSearchName = ""
    cardSearchName = ""
    cardSearchQualCode: string | null = null
    // cardSearchElo = "high"
    
    loading = true
    error = false
    errorText = ""
    leaderboardRegion = localStorage['lor:leaderboardRegion']
    players: Array<any> = []

    @Watch('cardSearchName')
    cardSearchNameChanged (after: string): void {
        let id = this.$asset.lor.cardkeys[after]
        if (id == undefined)
            id = null
        this.cardSearchQualCode = id
    }

    @Watch('leaderboardRegion')
    leaderboardRegionChanged (after: string): void {
        this.$stge.loc['lor:leaderboardRegion'] = after
        this.getTop5()
    }

    async created (): Promise<void> {
        this.getTop5()
        this.$meta.use({
            title: 'Legends of Runeterra | StatHub.gg',
            og: {
                image: this.$cdn.url('lor', ['static', 'game-icon'], 'png'),
                description: 'Legends of Runeterra player profiles, game data, statistics plus top master player decks.'
            }
        })
    }

    async getTop5 (): Promise<void> {
        this.loading = true
        try {
            this.players = await this.$api.get('lor', ['leaderboards-top-5', this.leaderboardRegion])
        } catch (e: any) {
            this.error = true
            this.errorText = e.response.data.detail
        }
        this.loading = false
    }

    get cardSearchImgUrl (): string | null {
        if (this.cardSearchQualCode == null)
            return null
        return this.$cdn.url('lor', ['cards', 'circle', this.cardSearchQualCode], 'png')
    }

    get validRiotIdTag (): boolean {
        const matches = this.playerSearchName.match('(.+)#([^ ]+)')
        return !!matches && matches[0] == this.playerSearchName
    }

    get profileUrl (): string | null {
        if (!this.validRiotIdTag)
            return null
        const vals = this.playerSearchName.split('#')
        return `/lor/profiles/${vals[0]}/${vals[1]}`
    }

}
