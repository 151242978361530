<template>
    <div class="row pt-3 mx-0 mx-lg-4 mx-xl-5">
        <div class="col-12 col-md-7 mt-0 mt-md-4">
            <h2 class="mt-2 mt-md-4">Legends of Runeterra Stats</h2>
            <div class="mt-4 mt-md-5 mx-auto">
                <Input
                    placeholder="Search Player by Name#Tag"
                    width="calc(50% + 70px)"
                    v-model="playerSearchName"
                    @keyup.enter="$refs.profileSearchButton.$el.click()"
                ></Input>
                <Button
                    class="ms-2"
                    style="min-width: 60px"
                    ref="profileSearchButton"
                    :to="profileUrl"
                >
                    <Icon :size="16" name="search" />
                </Button>
            </div>
            <div class="mt-2 mt-md-4 mx-auto">
                <Input
                    placeholder="Search Card by Name"
                    width="calc(50% + 70px)"
                    :display-img="cardSearchImgUrl"
                    v-model="cardSearchName"
                    @keyup.enter="$refs.cardSearchButton.$el.click()"
                ></Input>
                <Button
                    class="ms-2"
                    style="min-width: 60px"
                    ref="cardSearchButton"
                    :to="{name: 'LorCards', query: {search: cardSearchName}}"
                >
                    <Icon :size="16" name="chevron-right" />
                </Button>
            </div>
            <VenatusAd class="mt-3 mt-md-5 mx-3" v-if="$store.state.winWidth > 768" :height="200" />
            <VenatusAd class="mt-3 mt-md-5 mx-3" v-else :height="100" />
        </div>
        <div class="col-12 col-md-5">
            <Loading v-if="loading">LOADING</Loading>
            <Error v-else-if="error">{{errorText}}</Error>
            <div v-else class="text-left mt-3 mt-md-4 mx-3 mx-md-5">
                <h3 class="text-truncate text-left mx-1">Top 5 Masters</h3>
                <hr>
                <Tab class="mb-4" :options="$asset.lor.fullRegions" v-model="leaderboardRegion"></Tab>
                <router-link
                    v-for="player in players"
                    :key="'leaderboard_player_' + leaderboardRegion + player.rank"
                    :class="[player.rank < 10 ? 'top-bg': 'win-bg', 'mt-2 pt-2 pb-1 py-md-3 px-3 d-flex justify-content-between']"
                    :to="{name: 'LorProfile', params: {name: player.name, tag: $asset.lor.pogTagByRegion[leaderboardRegion]}}"
                >
                    <div class="">
                        <h5>{{player.rank + 1}}</h5>
                    </div>
                    <div>
                        {{player.name}}
                    </div>
                    <div class="text-warning">
                        {{player.lp}} LP
                    </div>
                </router-link>
                <div v-if="players.length < 5" class="null-bg mt-2 pt-2 pb-1 py-md-3 px-2 text-center">
                    <Icon name="exclamation-triangle" class="me-2" />
                    Ranks were recently reset
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Select from '@/components/Select.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
import Tab from '@/components/Tab.vue'

@Options({components: {Input, Button, Icon, Select, Loading, Tab, Error}})
export default class LorHome extends Vue {

    playerSearchName = ""
    cardSearchName = ""
    cardSearchQualCode: string | null = null
    // cardSearchElo = "high"
    
    loading = true
    error = false
    errorText = ""
    leaderboardRegion = localStorage['lor:leaderboardRegion']
    players: Array<any> = []

    @Watch('cardSearchName')
    cardSearchNameChanged (after: string): void {
        let id = this.$asset.lor.cardkeys[after]
        if (id == undefined)
            id = null
        this.cardSearchQualCode = id
    }

    @Watch('leaderboardRegion')
    leaderboardRegionChanged (after: string): void {
        this.$stge.loc['lor:leaderboardRegion'] = after
        this.getTop5()
    }

    async created (): Promise<void> {
        this.getTop5()
        this.$meta.use({
            title: 'Legends of Runeterra | StatHub.gg',
            og: {
                image: this.$cdn.url('lor', ['static', 'game-icon'], 'png'),
                description: 'Legends of Runeterra player profiles, game data, statistics plus top master player decks.'
            }
        })
    }

    async getTop5 (): Promise<void> {
        this.loading = true
        try {
            this.players = await this.$api.get('lor', ['leaderboards-top-5', this.leaderboardRegion])
        } catch (e: any) {
            this.error = true
            this.errorText = e.response.data.detail
        }
        this.loading = false
    }

    get cardSearchImgUrl (): string | null {
        if (this.cardSearchQualCode == null)
            return null
        return this.$cdn.url('lor', ['cards', 'circle', this.cardSearchQualCode], 'png')
    }

    get validRiotIdTag (): boolean {
        const matches = this.playerSearchName.match('(.+)#([^ ]+)')
        return !!matches && matches[0] == this.playerSearchName
    }

    get profileUrl (): string | null {
        if (!this.validRiotIdTag)
            return null
        const vals = this.playerSearchName.split('#')
        return `/lor/profiles/${vals[0]}/${vals[1]}`
    }

}
</script>
